<template>
    <div class="panel panel-default toggle_container">
        <div class="panel-heading"></div>
        <div class="row panel-body">
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date From</label>
                <DatePicker v-model="state.optionFields.startDate.val"
                            :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="date_picker col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship Date To</label>
                <DatePicker v-model="state.optionFields.endDate.val"
                            :input-attributes="{ class: 'form-control' }"></DatePicker>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Item</label>
                <Typeahead cdi_type='curb' v-bind:ID.sync='state.optionFields.curbSearchId.val'/>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>CDI Location</label>
                <select v-model="state.optionFields.cdiLocationId.val" class="form-control">
                    <option value="0">All</option>
                    <option v-for="[id, location] in cache.cdiLocationCache.CDI_LOCATIONS" v-bind:key="id" :value="id">
                        {{
                            location
                        }}
                    </option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Ship To City</label>
                <Typeahead cdi_type='city' v-bind:ID.sync='state.optionFields.shipToCity.val'/>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship to State</label>
                <select v-model="state.optionFields.shipToState.val" class="form-control">
                    <option value="0">None</option>
                    <option v-for="[stateAbbr, state] in cache.stateCache.STATES" v-bind:key="stateAbbr"
                            :value="stateAbbr">
                        {{ state }}
                    </option>
                </select>
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Ship to Zip</label>
                <input type="text" class='form-control' v-model="state.optionFields.shipToZip.val">
                <input type="hidden" v-model="state.optionFields.shipToZip.val">
            </div>
            <div class="col-md-3 col-sm-4 col-xs-6 form-group">
                <label>Company</label>
                <Typeahead cdi_type='company' v-bind:ID.sync='state.optionFields.companyId.val'/>
            </div>

            <div class="col-md-3 col-sm-4 col-xs-6 form-group" v-show="!toggleHide">
                <label>Trucking</label>
                <select class="form-control" v-model="state.optionFields.truckingType.val">
                    <option value="0">None</option>
                    <option v-for="[id, type] in cache.shippingCache.SHIPPING_OPTIONS" v-bind:key="id" :value="id">{{
                            type
                        }}
                    </option>
                </select>
            </div>
            <div v-show="!toggleHide" class='col-xs-12 toggle_target'>
                <div class="filter_separator">Has Item Type <small>(All if none are checked)</small></div>
            </div>
            <div v-for="[id, curb] in cache.curbTypeCache.CURB_TYPES_HISTORICAL" v-bind:key="id" v-show="!toggleHide"
                 class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <label><input type=checkbox :value="id" :false-value=null v-model="state.optionFields.hasItemType.val"> {{curb }}</label>
            </div>
            <div v-show="!toggleHide" class="col-md-3 col-sm-4 col-xs-6 form_grid">
                <div>
                    <label><input type="checkbox" checked v-model="state.optionFields.includeCdiStock.val"> Include CDI
                        Stock</label>
                </div>
            </div>

            <div class="col-xs-12">
                <button type="button" class="btn btn-default btn-sm" @click="toggleMoreFilters">
                    {{ (toggleHide) ? 'More' : 'Less' }} Filters
                </button>
            </div>
        </div>
    </div>
</template>

<script>

import {store} from "@/store/Report.store";
import cdiLocationCache from "@/cache/cdiLocation.cache.js";
import stateCache from "@/cache/state.cache.js";
import salesDataCache from "@/cache/salesData.cache.js";
import shippingCache from "@/cache/shipping.cache.js";
import userCache from "@/cache/user.cache.js";
import DatePicker from "../utils/DatePicker";
import Typeahead from "@/components/utils/typeaheads/Typeahead"
import curbTypeCache from '@/cache/curbType.cache.js';

export default {
    name: 'Options',
    components: {DatePicker, Typeahead},
    data() {
        return {
            state: store.state,
            toggleHide: true,
            cache: {
                cdiLocationCache,
                stateCache,
                salesDataCache,
                shippingCache,
                userCache,
                curbTypeCache,
            }
        }
    },
    methods: {
        toggleMoreFilters: function () {
            this.toggleHide = !this.toggleHide;
        },
    },
    watch: {
        toggleHide: function () {
            this.state.toggleHide = this.toggleHide;
        }
    }
}

</script>