<template>
    <div>
        <h1>Item Freight</h1>
        <form @submit.prevent="runReport">
            <Options/>
            <Fields/>
            <Results/>
        </form>
    </div>
</template>
<script>

import {store} from '@/store/Report.store.js';
import Options from "./item_freight_opt";
import Fields from "./utils/Fields";
import Results from "./utils/Results";
import DateHelpers from "@/store/DateHelpers";

export default {
    name: 'item_freight',
    components: {Results, Fields, Options},
    data() {
        return {
            state: store.state,
            optionFields: {
                startDate: {val: DateHelpers.getFirstOfTheMonth(), qsField: '[dates][actual_ship_from]'},
                endDate: {val: new Date().toLocaleDateString(), qsField: '[dates][actual_ship_to]'},
                cdiLocationId: {val: 0, qsField: '[numbers][t.cdi_location_id]'},
                companyId: {val: '0', qsField: '[numbers][d.company_id]', defaultVal: '0'},
                curbSearchId: {val: '', qsField: '[numbers][c.curb_id]'},
                hasItemType: {val: [], qsField: '[in][c.curb_type_id]', defaultVal: []},
                shipToState: {val: 0, qsField: '[strings][sa.state]'},
                truckingType: {val: 0, qsField: '[numbers][t.trucking_options_id]', defaultVal: 0},
                specialTag: {val: 0, qsField: '[numbers][d.branch_id]'},
                shipToZip: {val: '', qsField: '[strings][sa.zip]'},
                shipToCity: {val: '', qsField: '[strings][sa.city]', defaultVal: ''},
                includeCdiStock: {val: 0, qsField: '[params][include_cdi_stock]'}
            },
            groupByFields: {},
            dataFields: {
                tracker_id: {label: 'Order #', checked: true},
                cdi_location: {label: 'CDI Location', checked: true },
                nomenclature: {label: 'Item', checked: false},
                price_category: {label: 'Cabinet', checked: false},
                company_name: {label: 'Customer', checked: true},
                ship_date: {label: 'Ship Date', checked: true},
                curb_type: {label: 'Curb Type', checked: true},
                skid_weight: {label: 'Skid Weight', checked: true},
                skid_measurement: {label: 'Dimensions (LxWxH)', checked: true},
                destination: {label: 'Destination', checked: true},
                shipping_co: {label: 'Shipper', checked: true},
                cost_per_pound: {label: 'Cost / Lb.', checked: true},
                curb_price: {label: 'Curb Price', checked: true},
                trucking_options_id: {label: 'Shipment Type', checked: true},
                weighted_ship_cost: {label: 'Estimated Ship Cost By Weight', checked: true},
            }
        }
    },
    methods: {
        runReport: function () {
            store.runReport();
        }
    },
    created() {
        this.$appStore.setTitle(['Item Freight']);
        this.$set(store.state.results, this.$options.name, '');
        store.setReport(this.$options.name, this.optionFields, this.groupByFields, this.dataFields);
    }
}
</script>